<template>
  <div class=" full-width ">
    <v-form v-model="f1">
      NOUVELLE PROCEDURE
      <div class="header pa-4">
        <grid
          item-min-width="300px"
          class="align-center "
        >
          <translations
            placeholder="placeholder"
            :dense="_dense"
            :disabled="_disabled"
            :rules="required"
            label="Name"
            type="text"
            :clearable="_clearable"
            v-model="dataName"
            :readonly="_readonly"
          />
          <translations
            :form="computedIsFormValid"
            :is-new="documentIsNew"
            :label="$t('t.Description')"
            :translations.sync="computedDescription"
          />
          <v-checkbox
            :label="$t('t.Active')"
            class="ma-0"
            hide-details
            v-model="computedIsActive"
          />
          <v-autocomplete
            :label="$t('t.Documents')"
            :items="documentTypes"
          />
          <column-picker
            :attach="false"
            :clearable="true"
            :label="$t('t.AppliesTo')"
            :filters="{ 'document-types': [['accounts'],[]] }"
            :readonly="readonly"
            :selected-col-id.sync="dataApplyToId"
            :bool-cols-only="true"
          />
        </grid>
        <v-btn @click="addItem1">
          Ajouter une action
        </v-btn>
      </div>
      <div class="content">
        <div
          v-for="(item, i) in items"
          :key="i"
          class="pa-2"
        >
          <grid
            item-min-width="300px"
            class="align-center "
          >
            <v-select
              label="Action"
              :items="actions"
              v-model="dataAction"
            />
            <v-text-field
              type="number"
              label="Limite de crédit permanente"
              v-model="dataName"
            />
            <column-picker
              :attach="false"
              :clearable="true"
              :label="$t('t.AppliesTo')"
              :filters="{ 'document-types': [['accounts'],[]] }"
              :readonly="readonly"
              :selected-col-id.sync="computedActions[index]"
              :bool-cols-only="true"
            />
          </grid>
        </div>
      </div>
    </v-form>
  </div>
</template>
<script>

export default {
  components: {
    // Picker: () => import('@/components/picker'),
    // Picker2: () => import('@/components/picker2'),
    // TypedDocPicker2: () => import('@/components/typed-doc-picker2'),
    ColumnPicker: () => import('@/components/column-picker'),
    Grid: () => import('@/components/grid'),
    Translations: () => import('@/components/translations')

  },
  computed: {
    nameRules () {
      const MIN = 1
      const MAX = 100
      return [
        v => !!v.length || this.$t('t.IsRequired'),
        v => (v.length >= MIN && v.length <= MAX) || this.$t('t.Length')
      ]
    },
    computedAction () {
      return this.dataAction
    }
  },
  data () {
    return {
      readonly: false,
      dataAction: null,
      dataApplyToId: null,
      selectedId: 2,
      documentTypes: ['Comptes', 'Factures'],
      actions: ['Attribution des limites de crédits permanente', 'Création de tâche'],
      dataName: null,
      required: [v => this.checkRequired(v) || this.$t('t.IsRequired')],
      items: []
    }
  },
  methods: {

    addItem1 () {
      this.items.push({
        items: []
      })

      this.$waitFor(() => this.$refs?.items?.length).then(() => {
        this.$refs.items[this.$refs.items.length - 1].focus()
      })
    },
    checkRequired (v) {
      const r = !!v && !this.lodash.isEqual(v, {}) && !this.lodash.isEqual(v, { id: null })
      return r
    }
  }
}
</script>
<style lang="stylus" scoped>
.full-width
  width 100%

.header
  position sticky
  top 0

.header
  z-index 9999

.header
  position -webkit-sticky
  position sticky
  top 0
</style>
